﻿$.extend(UserInfo.prototype, UserInfoBalanceData.prototype);

UserInfo.Tries = 0;
UserInfo.TaxProvider = UserInfo.TaxProvider || EmptyTaxProvider;
UserInfo.current = null;
UserInfo.selfExclusionCheckInterval = 900000;
UserInfo.OnTwoStepNativeLogin = [];
UserInfo.UpdateBalanceThreshold = 2000;
UserInfo.CheckSessionInterval = 60000;

UserInfo.updateCurrentUser = function (callback)
{
    UserInfoPageMethods.getUserInfo(
        function (res)
        {
            var result = eval(res);

            if (result == "-1")
            {
                var isUserLoggedIn = UserInfo.current !== null;
                if (isUserLoggedIn)
                {
                    postNativeCallback(nativeCallbackKind.SessionExpired);
                }

                if (UserInfo.current) { UserInfo.showSessionExpired(); }
                UserInfo.logout();
            }
            else if (result == "-2")
            {
                if (UserInfo.current) { UserInfo.showMessage($string("Login").MultipleLogin); }
                UserInfo.logout();
            }
            else if (result == "-3")
            {
                if (UserInfo.current) { UserInfo.showMessage($string("Login").AccountRestricted); }
                UserInfo.logout();
            }
            else if (result == "-4")
            {
                if (UserInfo.current) { UserInfo.showMessage($string("Login").OneSessionAllowed); }
                UserInfo.logout();
            }
            else if (result == "-5")
            {
                if (UserInfo.current) { UserInfo.showMessage($string("Login").LockedUserAccount); }
                UserInfo.logout();
            }
            else if (result == "-1000")
            {
                underMaintence();
            }
            else
            {
                UserInfo.executeCommands(eval(result[4])); // evaluate because it"s serialized as a string by the server
                UserInfo.updateData(result);
            }

            if (callback && typeof callback === "function")
            {
                callback.call(this);
            }

        });
}

function visibilityChange() {
    if (typeof InactivityLogoutMinutes === "undefined"|| InactivityLogoutMinutes <= 0) {
        return;
    }
    if (!document.hidden) {
        UserInfo.CheckSessionUpdater.start();
        UserInfo.checkSession();
        SetLastActivityTime();
    } else {
        UserInfo.CheckSessionUpdater.stop();
    }
}

document.addEventListener("visibilitychange", visibilityChange, false);

function logoutUserByInactivity() {
    if(window.RegulationRuleType && window.RegulationRuleId === window.RegulationRuleType.Pennsylvania) {
        window.sessionStorage.setItem("redirectToLoginAfterLogout", "true");
    } else {
        UserInfo.showSessionExpired();
    }
    UserInfo.logout();
};

UserInfo.checkSession = function() {
    if (!InactivityLogoutMinutes) return;

    if (UserInfo.current && (new Date()).getTime() - getLastActivityTime() > InactivityLogoutMinutes * 60 * 1000) {
        if (!window.CasinoPlayerActivityAPIEnabled) {
            logoutUserByInactivity();
            return;
        }

        window.casinoPlayerActivity.checkActivity().then(function (isActive) {
            if (!isActive) {
                logoutUserByInactivity();
            }
        }).catch(logoutUserByInactivity);
    }
}

UserInfo.showSessionExpired = () => {
    UserInfo.showMessage($string("Login").SessionExpired);
}

UserInfo.updateExtSession = function ()
{
    if (!UserInfo.current) return;

    APIUser().refreshSession(
        function (res)
        {
            var log = new ESInfoLogger("UpdateSession");

            if (res.status.toLowerCase() == "failure")
            {
                log.addInfo("RefreshSession response: " + JSON.stringify(res));
                if (res.message) { UserInfo.showMessage("Error: " + res.message); }
                UserInfo.logout();
            }
            else
            {   // Set balance for seamless:
                if (res.balance && res.balance != "-1")
                {
                    UserInfo.SeamlessCustomerBalance = res.balance;
                    if (UserInfo.current)
                    {   //update seamless balance
                        UserInfo.current.updateSeamlessBalance(res.balance);
                        if (UserInfo.onUpdateBalance) { executeEvents(UserInfo.onUpdateBalance); }
                    }
                }
            }

            log.end();
        }
    );
}

UserInfo.setAcceptChangingOdds = function (acceptOdds)
{
    var val = parseInt(acceptOdds);
    if (!UserInfo.current || UserInfo.current.AcceptChangingOdds === val) return;

    UserInfo.current.AcceptChangingOdds = val ? val : AcceptChangingOddsModes.AcceptChangedOddsNever;

    executeEvents(UserInfo.onAcceptOddsChanged, this, UserInfo.current.AcceptChangingOdds);
}

UserInfo.prototype.dispatchIsPlacingBetsDisabledChanged = function (isPlacingBetsDisabled)
{
    if (this && this.EventsManager)
    {
        this.EventsManager.dispatchEvent("onIsPlacingBetsDisabledChanged", !!isPlacingBetsDisabled, true);
    }
};

UserInfo.tryObtainExternalLogin = function ()
{
    if (UserInfo.current) return;

    APIUser().status(
        function (res)
        {
            var log = new ESInfoLogger("ExternalLogin");

            if (res.status.toLowerCase() == "error")
            {
                log.addInfo("Status error message: " + res.message + ". Status response: " + JSON.stringify(res));
                UserInfo.showMessage(res.message);
                if (UserInfo.current) UserInfo.logout();
                return;
            }

            if (res.status.toLowerCase() == "real")
            {
                if (res.balance && res.balance != "-1")
                {
                    UserInfo.SeamlessCustomerBalance = res.balance;
                    if (UserInfo.current)
                    {
                        UserInfo.current.updateSeamlessBalance(res.balance);
                        if (UserInfo.onUpdateBalance) { executeEvents(UserInfo.onUpdateBalance); }
                    }
                }

                if (UserInfo.current) return;

                if (res.token)
                {
                    setTimeout(function () { UserInfo.tokenLogin(res); }, 10);
                }
                else
                {
                    UserInfo.updateData(res);
                }

                return;
            }

            // we have different checks for "anon" and "error" status because
            // we don"t have an error message for "anon" status
            if (res.status.toLowerCase() == "anon")
            {
                var hasUserName = UserInfo.current != null;
                var statusResponse = "Status response: " + JSON.stringify(res);
                var logMessage = hasUserName ? "Logout for " + UserInfo.current.userName + ". " + statusResponse : statusResponse;

                log.addInfo(logMessage);
                UserInfo.logout();
            }

            log.end();
        }
    );
}

UserInfo.tokenLogin = function (loginResult)
{
    let params = UserInfo.getParamsFromUrl();
    if (UserInfo.current) return;
    UserInfo.CurrentUserUpdater.restart();

    executeEvents(UserInfo.onTokenLoginReceived, {token: loginResult.token, playerId: loginResult.uid, brandId: params.brandId});
}

UserInfo.legacyWalletTokenLogin = function(loginResult)
{
    PageMethods.tokenLogin(loginResult.token,
        function (res)
        {
            var response = JSON.parse(res);
            if(response.Error)
            {
                var errorMsg =  UserInfo.getLoginErrorMessage(Object.assign({ message: response.Error }, response));
                UserInfo.showMessage(errorMsg);
                UserInfo.GTMLoginFailed(errorMsg);
                UserInfo.logout();
                return;
            }

            executeEvents(UserInfo.onTokenLogin, this, loginResult);

            var result = eval(res);
            UserInfo.updateData(result);
            UserInfo.GTMLoginSuccess();
        },
        function ()
        {
            UserInfo.GTMLoginFailed();
            UserInfo.logout();
        }
    );
}

UserInfo.updateBalance = function() {
    UserInfo.current && UserInfo.current.updateBalance();
}

UserInfo.prototype.updateBalance = function()
{
    var ref = this;

    UserInfo.updateOpenBetsData();
    UserInfoPageMethods.GetMyCurrentBalance(function (serverResponse) {
        if (!serverResponse)
        {
            return;
        }

        var response = JSON.parse(serverResponse);
        if (response.Error)
        {
            return;
        }

        ref.updateBalanceData(response);
        ref.balanceCallExecuted = true;
        if (ref.isBalancesInitiated())
        {
            executeEvents(UserInfo.onGetBalance);
        }
        if (UserInfo.onUpdateBalance) { executeEvents(UserInfo.onUpdateBalance); }
    });
}

UserInfo.prototype.isBalancesInitiated = function ()
{
    return this.balanceCallExecuted && this.openBetsCallFinished;
}

UserInfo.updateData = function (result)
{
    if (result.length >= 32 && result[31])
    {
        JWT_TOKEN = result[31];

        StorageUtils.saveToStorage("JWT_TOKEN", JWT_TOKEN, sessionStorage);
    }

    if (!UserInfo.current)
    {
        UserInfo.current = new UserInfo(result);
        document.body.classList.add("loggedin");
        UserInfo.setAcceptChangingOdds(result[35]);
        UserInfo.current.GetUserLimitationsInformation();
        if (currentAppType !== ApplicationType.Seamless) {
            UserInfo.current.updateBalance();
            UserInfo.BalanceUpdater.start();
        }
        if(UserInfo.SeamlessCustomerBalance) {
            UserInfo.current.updateSeamlessBalance(UserInfo.SeamlessCustomerBalance);
            if (UserInfo.onUpdateBalance) { executeEvents(UserInfo.onUpdateBalance); }
        }
        UserInfo.SelfExclusionUpdater.start();
        executeEvents(UserInfo.onLogin);
        startJsProfileUpdate();
    }
    else
    {
        UserInfo.current.updateData(result);
        executeEvents(UserInfo.onDataUpdated, this, result);
        if(window.RegulationRuleId && window.RegulationRuleType && window.RegulationRuleId !== window.RegulationRuleType.Danish) {
            executeEvents(UserInfo.onSessionKeepAlive, this, result);
        }
    }

    // save UserInfo FreeBets in session storage
    UserInfo.saveFreeBetsState();
}

UserInfo.prototype.parseFreeBets = function (data)
{
    return HashtableOf(data, BonusInfo, "BonusID");
}

UserInfo.prototype.toUserFormatedCurrencyString = function (amount)
{
    return this.formatAmount(amount, "toCurrencyFormat");
}

UserInfo.prototype.toUserLocalizedFormatedCurrencyString = function (amount) {
    return this.formatAmount(amount, "toCurrencyLocalizedFormat");
}

UserInfo.prototype.formatAmount = function(amount, formatMethod) {
    if (typeof (amount) === "string") {
		amount = amount.toStrippedNumberString();
	}

	var parsedAmount = parseFloat(amount);
	if (isNaN(parsedAmount)) {
		return "";
	}
	return this.currencyStringFormat[formatMethod](amount, this.currencyCode);
}

UserInfo.prototype.updateData = function (data)
{
    this.userID = data[0];
    this.userName = this.getUserName(data);
    this.FirstName = this.firstName = data[1];
    this.currencyId = +data[8];
    this.Currency = this.currencyCode = data[9];
    this.currencyStringFormat = data[10];
    this.FreeBets = data[12].length ? this.parseFreeBets(data[12]) : [];
    this.IsLinked = !!data[13];
    this.IsCashOutAllowed = !!data[14];
    this.CashOutFee = data[15];
    this.LoginName = data[16];
    this.IsSelfExcluded = data[20];
    this.SelfExclusionExpireDate = data[21];
    this.IsTimeouted = data[22];
    this.TimeoutFacilityExpireDate = data[23];
    this.MerchCustomerCode = data[24];
    this.PIN = data[25];
    this.LastName = data[27];
    this.Email = data[28];
    this.RegistrationDate = data[33];
    this.IsExternalyVerified = data[34];
    UserInfo.setAcceptChangingOdds(data[35]);
    this.IsPlacingBetsDisabled = data[36];
    this.dispatchIsPlacingBetsDisabledChanged(data[36]);
    this.IsAccountVerified = data[37];
    this.CountryOfBirth = data[38] ? data[38] : undefined;
    this.IsIdVerificationPeriodFailed = data[40];
    this.GeoVerificationData = data[41];
    this.IsIdVerified = data[42];
    this.AllowPlacingBet = data[43];
    this.CountryOfRegistration = data[44];
    this.OddsStyleId = data[45];
    this.FreeSpinsCount = data[46];
    this.TaxInfo = data[29] || UserInfo.getCountryTaxState();
    this.CurrencyName = data[47];
    this.HasCommunicationChannels = data[48];
    this.PersonalizationConsent = data[49];
    this.IsFirstLogin = data[50];
    this.ISOCurrencyCode = data[51];

    if (typeof(isEntWineEnabled) !== "undefined" && isEntWineEnabled)
    {
        this.GetEntWineBalance();
    }
    if (data.length >= 31 && data[30])
    {
        CryptoHelper.setConfig({
            key: data[30],
            iv: this.userID
        });
    }

    var balanceConfig = BalanceConfig.NONE;
    if (data.length > 18) {
        balanceConfig = data[18] * 1;
    }
    this.balanceConfig = balanceConfig;
    this.creditLimit =  data.length > 17 ? data[17] * 1 : 0;
    UserInfo.SetCryptoHelperConfig();
}

UserInfo.SetCryptoHelperConfig = function () {
    var userSessionCookie = Cookies.get("_usersession");
    if (userSessionCookie) {
        var userSessionCookieResult = JSON.parse(userSessionCookie);
        if (userSessionCookieResult && userSessionCookieResult.UserSessionToken && userSessionCookieResult.UserID) {

            CryptoHelper.setConfig({
                key: userSessionCookieResult.UserSessionToken,
                iv: userSessionCookieResult.UserID
            });
        }
        Cookies.remove("_usersession");
    }
}

UserInfo.updateOpenBetsData = function () {
    UserInfoPageMethods.getUserOpenBetsData(UserInfo.updateOpenBetsDataHandler);
}

UserInfo.updateOpenBetsDataHandler = function (data)
{
    if (data && UserInfo.current)
    {
        data = JSON.parse(data);

        UserInfo.current.updateOpenBets(
            {
                GetCustomerOpenBetsCountEx: data[0],
                OpenFreeBetsBalanceCustomerCurrency: data[1]
            });
        UserInfo.current.openBetsCallFinished = true;
        if (UserInfo.current.isBalancesInitiated())
        {
            executeEvents(UserInfo.onGetBalance);
        }
        executeEvents(UserInfo.onDataUpdated);
    }
}

UserInfo.prototype.getBalance = function()
{
    return !isCasinoWalletEnabled && this.CreditIsEnabled ? this.AvailableFundsCustomerCurrency : this.balance;
}

UserInfo.prototype.getBalanceString = function()
{
    return this.toUserFormatedCurrencyString(this.getBalance());
}

UserInfo.prototype.getUserName = function (data)
{
    return data[1] + " " + data[2];
}

UserInfo.prototype.updateSeamlessData = function (data)
{
    this.userName = "";
    this.firstName = "";
    this.OpenBetsCount = 0;
    this.currencyId = "";
    this.currencyCode = "";
    this.currencyStringFormat = "{0} {1}";
    this.openedBetsBalanceString = "";
    this.openFreeBetsBalanceString = "";
    this.openBalanceString = "";
    this.sportsbookTotalBalanceString = data.balance;
    this.casinoTotalBalanceString = "";
    this.FreeBets = [];
    this.IsLinked = "";
    this.IsCashOutAllowed = "";
    this.CashOutFee = "";
}

UserInfo.prototype.updateSeamlessBalance = function (balance)
{
    UserInfo.updateOpenBetsData();
    var userBalanceString = this.sanitizeSeamlessBalance(balance);
    this.balance = this.getFormattedBalance(userBalanceString);
    try
    {
        this.balance -= BetSlip.getLockedSumm();
    }
    catch (e) { }
    this.SportsbookTotalBalance = parseFloat(this.balance);
    this.sportsbookTotalBalanceString = this.toUserFormatedCurrencyString(this.balance);

    if (window.IsNegativeBalanceEnabled)
    {
        this.WithdrawableSportsbookBalance = this.SportsbookTotalBalance - this.SportsbookBonusBalance;
    }
    else
    {
        var withdrawableSportsbookBalanceLocal = 0;
        if (this.SportsbookBonusBalance !== undefined)
        {
            if (this.SportsbookTotalBalance > this.SportsbookBonusBalance)
            {
                withdrawableSportsbookBalanceLocal = this.SportsbookTotalBalance - this.SportsbookBonusBalance;
            }
            else
            {
                this.SportsbookBonusBalance = this.SportsbookTotalBalance;
            }
        }
        this.WithdrawableSportsbookBalance = withdrawableSportsbookBalanceLocal;
    }

    this.balanceDataUpdated = true;
    if (this.isBalancesInitiated())
    {
        executeEvents(UserInfo.onGetBalance);
    }
}

UserInfo.executeCommands = function (commands)
{
    if (!commands) return;

    for (var k in commands)
    {
        executeEvents(UserInfo.onCommand, UserInfo.current, commands[k]);
    }
}

UserInfo.showMessage = function (message)
{
    displayError(message);
    executeEvents(UserInfo.onMessage, UserInfo.current, message);
}

UserInfo.logout = function ()
{
    if (!UserInfo.current)
    {
        localStorage.removeItem("TaxInfo");
        sessionStorage.removeItem("UserInfoFreeBets");
        executeEvents(UserInfo.onNotLoggedIn);
        return;
    }

    window.scrollTo(0, 0);

    PageMethods.Logout(
        function (result)
        {
            if (result == "OK")
            {
                UserInfo.current = null;
                document.body.classList.remove("loggedin");
                // First we remove the free bets from the session storage
                sessionStorage.removeItem("UserInfoFreeBets");
                localStorage.removeItem("TaxInfo");
                Cookies.remove("TaxInfo");
                Cookies.remove("extrnlssid");
                Cookies.remove("jwtToken");
                UserInfo.TaxProvider = typeof taxProvider !== "undefined" && taxProvider || EmptyTaxProvider;

                if (IsAPIDefined()) { APIUser().logout(); }

                executeEvents(UserInfo.onLogout);
                executeEvents(UserInfo.onAfterLogout);

                if (!IsSeamlessOrWallet) {
                    ApplicationHistory.push("/");
                    setTimeout(function () {
                        window.location.reload()
                    }, 0);
                } else {
                    Application && Application.goHome();
                }
                UserInfo.BalanceUpdater.stop();
                UserInfo.SelfExclusionUpdater.stop();
            }
            else
            {
                UserInfo.showMessage($string("General").ServerError);
            }
        },
        function () { }
    );
}

UserInfo.prototype.getUserName = function (data)
{
    return data[1] + " " + data[2];
}

UserInfo.jwtLogin = function (token) {
    if (UserInfo.current) return;

    UserInfo.Tries++;
    UserInfo.CurrentUserUpdater.restart();
    executeEvents(UserInfo.onJwtLoginReceived, token);
}

UserInfo.danishNemIdLogin = function (response) {
    if (UserInfo.current) return;
    let result = JSON.parse(response);
    if(result.Error) {
        if(result.Error.includes("restrictedLegalText")){
            UserInfo.proceedToSecondStep({
                secondStepToken: result.Error.split(":")[1]
            });
        }
        else {
            UserInfo.processLoginError(Object.assign({ message: result.Error }, result));
        }
    }
    else {
        UserInfo.continueLogin(result);
    }
}

UserInfo.proceedToSecondStep = function (request) {
    executeEvents(UserInfo.onTwoStepLogin, UserInfo.current, request);
}

UserInfo.secondStepLogin = function (request) {
    UserInfo.CurrentUserUpdater.restart();
    executeEvents(UserInfo.onTwoStepLoginContinue, request);
}

UserInfo.continueLogin = function (res)
{
    UserInfo.updateData(res);
    UserInfo.GTMLoginSuccess();
    if (res[45] !== null && res[45] !== undefined)
    {
        Data.setOddStyleGlobal(res[45]);
    }
    executeEvents(UserInfo.onLoginSucceed);
}

UserInfo.serializeFreeBets = function ()
{
    if (!(UserInfo.current && UserInfo.current.FreeBets && Array.getLength(UserInfo.current.FreeBets) > 0))
    {
        return [];
    }

    var state = [], i,
        freeBets = UserInfo.current.FreeBets;

    for (i in freeBets)
    {
        state.push(freeBets[i].serialize());
    }

    return state;
};

UserInfo.deserializeFreeBets = function (freeBets)
{
    if (!freeBets) { return []; }

    var result = [], i;

    for (i in freeBets)
    {
        if (!freeBets[i]) { continue; }

        result.push(new BonusInfo(freeBets[i]));
    }

    return result;
};

UserInfo.saveFreeBetsState = function ()
{
    if (!WebStorage.isSupported()) { return; }

    var freeBets = UserInfo.serializeFreeBets();
    StorageUtils.saveToStorage("UserInfoFreeBets", JSON.stringify({ FreeBets: freeBets }), sessionStorage);
};

UserInfo.saveCountryTaxState = function (taxInfo)
{
    if (!WebStorage.isSupported() || !taxInfo) { return; }

    StorageUtils.saveToStorage("TaxInfo", JSON.stringify(taxInfo), localStorage);

};

UserInfo.getCountryTaxState = function ()
{
    if (!WebStorage.isSupported()) { return; }

    var taxInfo = localStorage.getItem("TaxInfo");

    if (!taxInfo && Cookies.get("TaxInfo"))
    {
        // new user registration cookies
        taxInfo = JSON.parse(Cookies.get("TaxInfo")).TaxInfo;
        return taxInfo;
    }
    return JSON.parse(taxInfo);
}

UserInfo.GTMLoginSuccess = function () {
    var arrayRegulatedFields = ["TaxInfo", "balance", "balanceCallExecuted", "balanceConfig", "balanceDataUpdated", "EventsManager",
        "GeoVerificationData", "LoginName", "MerchCustomerCode", "LastName", "userName", "Email", "FirstName", "firstName", "entwineBalance",
        "entwineBalanceString", "PIN", "Currency", "CountryOfBirth"];

    function filterRegulatedFields(userInfo) {
        var userData = Object.assign({}, userInfo);
        for (var i = 0; i < arrayRegulatedFields.length; i++) {
            delete userData[arrayRegulatedFields[i]];
        }
        return userData;
    }

    function retry() {
        setTimeout(UserInfo.GTMLoginSuccess, 5000);
    };

    if (UserInfo.current && UserInfo.current.isBalancesInitiated()) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "login",
            loginItems: {
                category: "login",
                action: "success",
                timestamp: serverdate,
                attempt: UserInfo.Tries
            },
            user: filterRegulatedFields(UserInfo.current)
        });

        UserInfo.Tries = 0;
    }
    else {
        retry();
    }
}

UserInfo.GTMLoginFailed = function (errorMsg) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: "login",
        loginItems: {
            category: "login",
            action: "failed",
            attempt: UserInfo.Tries,
            error: errorMsg,
            timestamp: serverdate
        }
    });
}

// returns UserInfo FreeBets from session storages
UserInfo.getFreeBetsFromStorage = function ()
{
    if (!WebStorage.isSupported()) { return []; }

    var sdata = sessionStorage.getItem("UserInfoFreeBets");
    if (!sdata) { return []; }

    var data = JSON.parse(sdata);
    if (!data) { return []; }

    return UserInfo.deserializeFreeBets(data.FreeBets);
};

UserInfo.prototype.GetEntWineBalance = function () {
    var ref = this;
    UserInfoPageMethods.getEntWineBalance(function (res) {
        ref.entwineBalance = res;
        ref.entwineBalanceString = ref.currencyStringFormat.toCurrencyFormat(ref.entwineBalance, ref.currencyCode);
        setValueOfHtmlElement("entwine_balance", ref.entwineBalanceString);
    })
}

UserInfo.prototype.GetUserLimitationsInformation = function () {
    var ref = this;
    UserInfoPageMethods.GetUserLimitationsInformation(
        function (result) {
            var res = JSON.parse(result);
            ref.HasUserReachedHisTimeLimit = res.HasUserReachedHisLimits;
            ref.HasUserConfirmedHisLimits = res.HasUserConfirmedHisLimits;
            ref.UserProfileActivationDate = res.UserProfileActivationDate;

            executeEvents(UserInfo.onDataUpdated);
        });
}

UserInfo.GetUserLimitationsInformation = function () {
    UserInfo.current && UserInfo.current.GetUserLimitationsInformation()
}

UserInfo.checkSelfExclusion = function() {
    UserInfoPageMethods.CheckSelfExclusion(
        function (result)
        {
            var isExcluded = JSON.parse(result);
            if (isExcluded)
            {
                if (UserInfo.current) { UserInfo.showMessage($string("Login").AccountRestricted); }
                UserInfo.logout();
            }
        });
}

UserInfo.getMobileLobbyURL = function ($elem)
{
    "use strict";

    if (!$elem || APIUser().getMobileLobbyURL === undefined)
    {
        return false;
    }

    $elem.attr("href", APIUser().getMobileLobbyURL());

    return false;
};

UserInfo.getMobileBankURL = function ($elem)
{
    "use strict";

    if (!$elem || APIUser().getMobileBankURL === undefined)
    {
        return false;
    }

    $elem.attr("href", APIUser().getMobileBankURL());

    return false;
};

UserInfo.RecursiveUpdater = (function () {
    function RecursiveUpdater (method, interval)
    {
        this.timer = 0;
        this.interval = interval;
        this.method = method;
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.restart = this.restart.bind(this);
        this.update = this.update.bind(this);
    };

    RecursiveUpdater.prototype.start = function ()
    {
        this.timer = setTimeout(this.update, this.interval);
    };

    RecursiveUpdater.prototype.stop = function ()
    {
        clearTimeout(this.timer);
    };

    RecursiveUpdater.prototype.restart = function ()
    {
        this.stop();
        this.start();
    };

    RecursiveUpdater.prototype.update = function ()
    {
        this.method();
        this.start();
    }

    return RecursiveUpdater;
})();

UserInfo.loadUser = function () {
    UserInfo.CurrentUserUpdater.restart();
    UserInfo.updateCurrentUser(() => {
        if (!UserInfo.current) return;
        UserInfo.GTMLoginSuccess();
        if (IsAPIDefined() && currentAppType !== ApplicationType.Platform)
        {
            UserInfo.ExtSessionUpdater.start();
            UserInfo.updateExtSession();
        }
    });
};

UserInfo.processLoginError = function (error) {
    if(error.message === "showForgottenPassPopup" && document.getElementById('forgotYourPass_a') !== null){
        Application.navigateTo('forgot-password-panel');
    }
    else
    {
        let message = UserInfo.getLoginErrorMessage(error);
        UserInfo.GTMLoginFailed(message);
        if (message)
        {
            UserInfo.showMessage(message);
        }
        executeEvents(UserInfo.onLoginFailed, this);
        UserInfo.logout();
    }
};

UserInfo.getLoginErrorMessage = function (error) {
    if(error.message.includes("restrictedCountry") || error.message.includes("restrictedByCountryIP"))
    {
        let country = error.message.split(":")[1];
        let restrictedCountryText = country ? $string('Login').LoginRestrictedPart2 + country + '\n\r' : '';
        return $string('Login').LoginRestrictedPart1 + '\n\r' + restrictedCountryText + $string('Login').LoginRestrictedPart3;
    }
    if(error.message.includes("restrictedRegion"))
    {
        let region = error.message.split(":")[1];
        return $string('Login').LoginRestrictedPart1 + '\n\r' + $string('Login').LoginRestrictedPart5 + region + '\n\r' + $string('Login').LoginRestrictedPart6;
    }
    switch(error.message) {
        case "ValidationError":
            return error.restrictedEmailIsVerified || error.KYCAutoFreezed;
        case "restrictedAccount:frozen":
            return $string('Login').AccountRestricted;
        case "serverError":
            return $string('General').ServerError;
        case "tooManyLoginAttempts":
            return $string('XSLT').TooManyLoginAttemps;
        default:
            return $string('XSLT').PerformOperationError;
    }
};

UserInfo.processLoginApiError = function (error) {
    UserInfo.Tries++;
    UserInfo.GTMLoginFailed(error.message);
}

UserInfo.trackOneTimeTokenLogin = function() {
    delete Application.onAfterApplicationInit["trackOneTimeTokenLogin"];
    const token = Cookies.get("OneTimeApiLoginToken");
    if (token) {
        var parts = token.split(",");
        UserInfo.oneTimeTokenLogin(parts[0], parts[1]);
        Cookies.remove("OneTimeApiLoginToken");
    }
};

UserInfo.oneTimeTokenLogin = function(oneTimeToken, userId) {
    const onSuccess = () => {
        jsRequire("/JSComponents/Utils/nativeAppCallbacks.js");
        postNativeCallback(nativeCallbackKind.RegisterSucceed, userId);
        (new EventsManager()).dispatchEvent("oneTimeTokenLogin", null, true);
        delete UserInfo.onLoginSucceed.oneTimeTokenLoginSuccess;
    }
    UserInfo.onLoginSucceed.oneTimeTokenLoginSuccess = onSuccess;
    executeEvents(UserInfo.onOneTimeTokenReceived, oneTimeToken, userId);
};

UserInfo.oneTimeTokenLoginFailed = function (message) {
    document.body.classList.remove("loggedin");
    executeEvents(UserInfo.onOneTimeTokenLoginFailed, message);
    UserInfo.logout();
    executeEvents(UserInfo.onLogout);
    executeEvents(UserInfo.onAfterLogout);
};

UserInfo.checkSeamlessLoginError = function () {
    var seamlessLoginErrorValue = Cookies.get("seamlessLoginError");
    if (seamlessLoginErrorValue) {
        var seamlessLoginError = JSON.parse(seamlessLoginErrorValue);
        UserInfo.processLoginError(seamlessLoginError);
        Cookies.remove("seamlessLoginError");
    }
};

UserInfo.checkSeamlessLoginSuccess = function () {
    var seamlessLoginValue = Cookies.get("seamlessLoginSuccess");
    if (seamlessLoginValue) {
        if (!UserInfo.current) return;
        UserInfo.GTMLoginSuccess();
        Cookies.remove("seamlessLoginSuccess");
    }
};

typeof extDict !== "undefined" && extDict["/JSComponents/Data/UserInfo.ext.js"]();

window.addEventListener("load", function ()
{
    Application.onAfterApplicationInit["trackOneTimeTokenLogin"] = UserInfo.trackOneTimeTokenLogin;
    setTimeout(function ()
    {
        UserInfo.BalanceUpdater = new UserInfo.RecursiveUpdater(UserInfo.updateBalance, BalanceUpdateTimeout);
        UserInfo.CurrentUserUpdater = new UserInfo.RecursiveUpdater(UserInfo.updateCurrentUser, BalanceUpdateTimeout);
        UserInfo.SelfExclusionUpdater = new UserInfo.RecursiveUpdater(UserInfo.checkSelfExclusion, UserInfo.selfExclusionCheckInterval);
        UserInfo.CheckSessionUpdater = new UserInfo.RecursiveUpdater(UserInfo.checkSession, UserInfo.CheckSessionInterval);
        UserInfo.ExtSessionUpdater = new UserInfo.RecursiveUpdater(UserInfo.updateExtSession, RefreshExternalSessionTimeout);

        UserInfo.CurrentUserUpdater.start();
        UserInfo.updateCurrentUser(() => {
            if (IsAPIDefined() && currentAppType !== ApplicationType.Platform)
            {
                UserInfo.ExtSessionUpdater.start();
                UserInfo.updateExtSession();
            }
            if (IsAPIDefined() && currentAppType === ApplicationType.Seamless)
            {
                UserInfo.checkSeamlessLoginSuccess();
            }
        });

        if (InactivityLogoutMinutes > 0) {
            UserInfo.CheckSessionUpdater.start();
        }

        if (window.sessionStorage.getItem("redirectToLoginAfterLogout")) {
            window.sessionStorage.removeItem("redirectToLoginAfterLogout");
            Application.openResponsiveLogin();
            UserInfo.showSessionExpired();
        }
    }, 0);

    UserInfo.checkSeamlessLoginError();
});

window.addEventListener("load", function()
{
    if (UserInfo.TaxProvider !== EmptyTaxProvider)
    {
        UserInfo.saveCountryTaxState(UserInfo.TaxProvider.TaxInfo);
    }
});
